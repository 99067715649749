import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  close(event) {
    if (!this.#shouldRestore) return
    event.preventDefault()
    window.history.back()
    this.#layer.remove()
  }

  get #shouldRestore() {
    return !this.#isFirstHistoryEntry
  }

  get #isFirstHistoryEntry() {
    return (
      !window.history.state.turbo ||
      window.history.state.turbo.restorationIndex === 0
    )
  }

  get #layer() {
    return this.element.closest("turbo-frame")
  }
}
