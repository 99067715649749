import { Controller } from "@hotwired/stimulus"
import lazysizes from "lazysizes"

const isScrollable = function (element) {
  const hasScrollableContent = element.scrollHeight > element.clientHeight
  const overflowYStyle = window.getComputedStyle(element).overflowY
  const isOverflowHidden = overflowYStyle.indexOf("hidden") !== -1
  return hasScrollableContent && !isOverflowHidden
}

const getScrollableParent = function (ele) {
  return !ele || ele === document.body
    ? document.body
    : isScrollable(ele)
    ? ele
    : getScrollableParent(ele.parentNode)
}

export default class extends Controller {
  openLightbox(event) {
    this.#storeScrollPosition()
    this.element.setAttribute("data-lightbox", "")
    event.target.scrollIntoView()
    lazySizes.autoSizer.checkElems()
  }

  closeLightbox() {
    this.element.removeAttribute("data-lightbox")
    this.#restoreScrollPosition()
    lazySizes.autoSizer.checkElems()
  }

  #storeScrollPosition() {
    this.#previousScrollPosition = this.#scrollParent.scrollTop
  }

  #restoreScrollPosition() {
    this.#scrollParent.scrollTop = this.#previousScrollPosition
  }

  set #previousScrollPosition(value) {
    this.element.dataset.scrollPosition = value
  }

  get #previousScrollPosition() {
    return this.element.dataset.scrollPosition
  }

  get #scrollParent() {
    return getScrollableParent(this.element)
  }
}
