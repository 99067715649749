import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["frame", "template", "page"]

  addLayer() {
    let clone = this.templateTarget.content.firstChild.cloneNode(true)
    this.element.append(clone)
  }

  onFrameRender(event) {
    if (!this.hasTemplateTarget) return
    this.pageTargets.forEach((page) => {
      page.dataset.current = page.parentElement === event.target
    })
    this.frameTargets.forEach((frame) => {
      frame.dataset.current = frame === event.target
    })
  }

  onBeforeCache() {
    this.frameTargets.forEach((frame) => {
      if (frame.innerHTML.trim() === "") {
        frame.remove()
      }
    })
  }

  frameTargetDisconnected() {
    this.frameTargets.forEach((frame) => {
      frame.dataset.current = frame === this.#currentFrame
    })
  }

  pageTargetConnected() {
    this.pageTargets.toReversed().forEach((page, index) => {
      page.dataset.layerDepth = index + 1
    })
  }

  pageTargetDisconnected() {
    this.pageTargets.toReversed().forEach((page, index) => {
      page.dataset.current = page === this.#currentPage
      page.dataset.layerDepth = index + 1
    })
  }

  get #currentFrame() {
    return this.frameTargets[this.frameTargets.length - 1]
  }

  get #currentPage() {
    if (this.hasFrameTarget) {
      return this.pageTargets.find(
        (page) => page.parentElement === this.#currentFrame
      )
    } else {
      return this.pageTarget
    }
  }
}
