import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  sortYear() {
    if (this.#sortOrder === "asc") {
      this.#sortOrder = "desc"
    } else {
      this.#sortOrder = "asc"
    }
  }

  set #sortOrder(value) {
    this.element.dataset.sortOrder = value
  }

  get #sortOrder() {
    return this.element.dataset.sortOrder
  }
}
