import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  storeScrollPosition() {
    this.element.dataset.scrollPosition = this.element.scrollTop
  }

  restoreScrollPosition() {
    this.element.scrollTop = this.element.dataset.scrollPosition
  }
}
