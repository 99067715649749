import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  onFrameRender() {
    if (this.#idUpdated) return
    this.element.id = `layer-${Date.now()}`
    this.element.dataset.current = "true"
  }

  onBeforeCache() {}
  onBeforeFetchRequest() {}
  onBeforeFrameRender() {}
  onFrameLoad() {}
  onFrameMissing() {}
  onLoad() {}
  onRender() {}

  close() {
    setTimeout(() => {
      this.element.remove()
    }, 1)
  }

  get #idUpdated() {
    this.element.id !== "layer"
  }
}
